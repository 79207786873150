import React from 'react';
import styles from './Footer.module.css';
import LOGO from '../../assets/Logo/logo.png';
import FooterLinks from './FooterLinks';
import SocialIcons from './SocialIcons';

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo_routing}>
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <div className={styles.abLogoImageContainer}>
              <img
                src={LOGO}
                alt="Alien Brains Logo"
                className={styles.abLogoImage}
              />
            </div>
            <span className={`footerButtonText ${styles.logoName}`}>
              ALIEN BRAINS
            </span>
          </div>
          <SocialIcons />
        </div>
        <FooterLinks />
      </div>
      <span className={`footerButtonText ${styles.copyrightText}`}>
        Copyright: Alien Brains Pvt Ltd
      </span>
    </div>
  );
};

export default Footer;
