import React from 'react';
import Icon from '../Icon';
import styles from './Footer.module.css';

const SocialIcons = () => {
  return (
    <div className={styles.socialMedia} data-testid="social-icons">
      <span className={styles.icon}>
        <Icon
          name="facebook-square"
          size="lg"
          color="black"
          isBrand={true}
          href="https://www.facebook.com/alienbrains.in/"
        />
      </span>
      <span className={styles.icon}>
        <Icon
          name="youtube"
          size="lg"
          color="black"
          isBrand={true}
          href="https://www.youtube.com/channel/UCW2xDwSAZs9Yuea_aMrMNBQ"
        />
      </span>
      <span className={styles.icon}>
        <Icon name="twitter" size="lg" color="grey" isBrand={true} disabled />
      </span>
    </div>
  );
};

export default SocialIcons;
