import React from 'react';
import ButtonLinks from './buttonLinks';
import { BLOG_URL } from '../../constants';
import styles from './Footer.module.css';

const FooterLinks = () => {
  return (
    <div className={styles.linkContainer}>
      <div className={styles.link}>
        <ButtonLinks text="BLOG" url={BLOG_URL} isExternalLink />
        <ButtonLinks text="ABOUT US" url="/#aboutus" />
        <ButtonLinks text="OUR BRAND" url="/#brands" />
      </div>
      <div className={styles.link}>
        <ButtonLinks text="TEAM" url="/#team" disabled />
        <ButtonLinks text="JOIN WITH US" url="/#joinwithus" />
        <ButtonLinks text="PRIVACY POLICY" url="/privacy_policy" />
      </div>
    </div>
  );
};

export default FooterLinks;
