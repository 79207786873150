import React from 'react';
import PropTypes from 'prop-types';

const ButtonLinks = ({ text, url, isExternalLink, disabled = false }) => {
  return (
    <a
      data-testid="footer-button"
      href={url}
      className="footerButtonText"
      style={disabled ? { cursor: 'not-allowed', color: 'grey' } : {}}
      target={isExternalLink ? '_blank' : ''}>
      {text}
    </a>
  );
};

export default ButtonLinks;

ButtonLinks.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  isExternalLink: PropTypes.bool
};

ButtonLinks.defaultProps = {
  url: '',
  isExternalLink: false
};
